import { Group, Math as ThreeMath,  Vector3 } from "three";

import PanelState from "./Panel/PanelState";

import Scheduler from "../Utils/Scheduler";

import {EDIT_MODES, SAMPLE_MODES} from "./Panel/PanelActions"

import {UI} from "./VSSScene";

import { generateUVMap, makeUIObject, triangle, indicator } from "../Utils/GeometryHelpers";
import { COLORS } from "../Constants";

let positions = {
    editModeTarget: new Vector3(),
    selectionModeTarget: new Vector3(),
    selectedSampleTarget: new Vector3(),
    selectedArpTarget: new Vector3(),
    recordButtonTarget: new Vector3(),
    overwriteButtonTarget: new Vector3(),
    vibratoButtonTarget: new Vector3(),
    demoButtonTarget: new Vector3(),
    mpRecordButtonTarget: new Vector3(),
    mpPlayButtonTarget: new Vector3(),
    arpSelectModeTarget: new Vector3(),
}

const editButtonNames = [
    "Button_Attack",
    "Button_Decay",
    "Button_Loop",
    "Button_Release",
    "Button_Sustain",
]

const selectionButtonNames = [
    "Button_Select_1",
    "Button_Select_2",
    "Button_Select_3",
    "Button_Select_4",
    "Button_Select_5",
    "Button_Select_6",
    "Button_Select_7",
    "Button_Select_8",
    "Button_Select_9",
    "Button_Select_10",
    "Button_Select_11",
    "Button_Select_12",
]


function isA(object) {

    if(match(object, editButtonNames)) {
        return "EDIT_BUTTON"
    }

    if(match(object, selectionButtonNames)) {
        return "SELECTION_BUTTON"
    }

}

function match(object, types) {
    let f = types.findIndex(t=>t === object.name) 
    return f > -1;
}


export default (scene, camera) => {
    
    let group = new Group();
        group.name = "UI2"

    let VSSObjects = {};

    let editMode = new Group();

    var editModeMesh = triangle(2,1,0.125,COLORS.green); //makeUIObject(2, 1, 0.125, UI.texture, generateUVMap(0.25+0.125, 0.75+0.125, 0.25/2, 0.25/2));
        editModeMesh.rotateX(ThreeMath.degToRad(-30));
        editModeMesh.position.z = 0;
        editModeMesh.position.y = 0.5;
        editModeMesh.visible = false;
        editMode.add(editModeMesh);
        group.add(editMode);

    let soundSelection = new Group();

    var plane1 = indicator(2.8, 0.2, 0.125, COLORS.white); 
        plane1.rotateX(ThreeMath.degToRad(-90));
        plane1.position.y = 0.47;
        soundSelection.add(plane1);
        group.add(soundSelection);

    let arpSelection = new Group();

    var arpSelectionMesh = indicator(2.8, 0.2, 0.125, COLORS.darkblue); 
        arpSelectionMesh.rotateX(ThreeMath.degToRad(-90));
        arpSelectionMesh.position.z = 0.03;
        arpSelectionMesh.position.y = 0.47;
        arpSelection.add(arpSelectionMesh);
        group.add(arpSelection);

    let vibratoSelection = new Group();

    var vibratoSelectionMesh = indicator(2.8, 0.2, 0.125, COLORS.yellow); 
        vibratoSelectionMesh.rotateX(ThreeMath.degToRad(-90));
        //vibratoSelectionMesh.position.z = 0.03;
        vibratoSelectionMesh.position.y = 0.1;
        vibratoSelectionMesh.visible = false;
        vibratoSelection.add(vibratoSelectionMesh);
        
        group.add(vibratoSelection);

    let arpMode = new Group();

    var arpModeMesh = indicator(2.8, 0.2, 0.125, COLORS.darkblue); 
        arpModeMesh.rotateX(ThreeMath.degToRad(-90));
        arpModeMesh.position.y = 0.1;
        arpModeMesh.visible = true;
        arpMode.add(arpModeMesh);
        
        group.add(arpMode);

    let sampleProgress = new Group();
    var sampleProgressMesh = makeUIObject(1.4, 1.4, 0.5, UI.texture, generateUVMap(0.5, 0.75,0.25, 0.25))
        sampleProgressMesh.position.y = 0.1;
        sampleProgress.visible = false;
        sampleProgressMesh.rotateX(ThreeMath.degToRad(-85));
        sampleProgressMesh.rotateZ(ThreeMath.degToRad(90));
        sampleProgress.add(sampleProgressMesh);

    let overwriteProgress = new Group();
        var overwriteProgressMesh  = makeUIObject(1.4, 1.4, 0.5, UI.texture, generateUVMap(0.5, 0.75,0.25, 0.25))
        overwriteProgressMesh.position.y = 0.1;
        overwriteProgress.visible = false;
        overwriteProgressMesh.rotateX(ThreeMath.degToRad(-85));
        overwriteProgressMesh.rotateZ(ThreeMath.degToRad(90));
        overwriteProgress.add(overwriteProgressMesh); 

    let musicRecordProgress = new Group();
    var musicRecordMesh = makeUIObject(1.4, 1.4, 0.5, UI.texture, generateUVMap(0.75, 0.75,0.25, 0.25))
        musicRecordMesh.position.y = 0.1;
        musicRecordMesh.rotateX(ThreeMath.degToRad(-85));
        musicRecordMesh.rotateZ(ThreeMath.degToRad(90));
        musicRecordProgress.visible = false;
        musicRecordProgress.add(musicRecordMesh); 

    let musicPlaybackProgress = musicRecordProgress.clone();
        musicPlaybackProgress.visible = false; 

    let demoProgress = new Group();
    var demoProgresMesh = makeUIObject(1.4, 1.4, 0.5, UI.texture, generateUVMap(0.25, 0.75,0.25, 0.25))
        demoProgresMesh.position.y = 0.1;
        demoProgresMesh.rotateX(ThreeMath.degToRad(-85));
        demoProgresMesh.rotateZ(ThreeMath.degToRad(90));
        demoProgress.add(demoProgresMesh);
        
        group.add(sampleProgress); 
        group.add(overwriteProgress);
        group.add(demoProgress);
        group.add(musicPlaybackProgress);
        group.add(musicRecordProgress);
           

        let selectSample = (state) => {
            let name = selectionButtonNames[state.SAMPLE_SELECTION_VALUE];
            let button = getObject(name)
            positions.selectedSampleTarget.copy(button.position);
        }

        let selectArp = (state) => {
            let name = selectionButtonNames[state.ARP_SELECTION_VALUE];
            let button = getObject(name)
            positions.selectedArpTarget.copy(button.position);
        }

        let selectionMade = (editObject)=>{
            let {state} = PanelState;
            
            editModeMesh.visible = state.EDIT_MODE !== EDIT_MODES.DEFAULT;
            selectSample(state);
            selectArp(state);
            vibratoSelectionMesh.visible = state.VIBRATO;
            arpModeMesh.visible = state.SELECTION_MODE === EDIT_MODES.ARP_SELECT;
            
            sampleProgress.visible = state.SAMPLE_MODE === SAMPLE_MODES.RECORD;
            overwriteProgress.visible  = state.SAMPLE_MODE === SAMPLE_MODES.OVERWRITE;

            demoProgress.visible = state.DEMO_STATE === Scheduler.STATES.PLAYING;

            musicPlaybackProgress.visible = state.MUSIC_RECORDER_STATE === Scheduler.STATES.PLAYING;
            musicRecordProgress.visible = 
                state.MUSIC_RECORDER_STATE === Scheduler.STATES.RECORDING || 
                state.MUSIC_RECORDER_STATE === Scheduler.STATES.WAITING_FOR_INPUT;

        }

    

    PanelState.on("update", selectionMade);
    PanelState.on("loadedKeyboard", (objects)=>{
    
        VSSObjects = objects;

       positions.recordButtonTarget.copy(getObject("Button_Sample").position)
       positions.overwriteButtonTarget.copy(getObject("Button_Overwrite").position);
       positions.mpRecordButtonTarget.copy(getObject("Button_MP_Record").position);
       positions.mpPlayButtonTarget.copy(getObject("Button_MP_PlayStop").position);
       positions.demoButtonTarget.copy(getObject("Button_Demo_StartStop").position);
       positions.vibratoButtonTarget.copy(getObject("Button_Vibrato").position);
       positions.arpSelectModeTarget.copy(getObject("Button_ModeSelect_ArpSelect").position)


       sampleProgress.position.copy(positions.recordButtonTarget);
       overwriteProgress.position.copy(positions.overwriteButtonTarget);
       musicRecordProgress.position.copy(positions.mpRecordButtonTarget);
       musicPlaybackProgress.position.copy(positions.mpPlayButtonTarget);
       demoProgress.position.copy(positions.demoButtonTarget);

        // selectionButtons = VSSObjects.controlKeys.children.filter(o=>match(o, selectionButtonNames));
        // editButtons = VSSObjects.controlKeys.children.filter(o=>match(o, editButtonNames));

        editModeMesh.position.copy(new Vector3(-3.15,0.6,-1.1));
        vibratoSelection.position.copy(positions.vibratoButtonTarget);
        arpMode.position.copy(positions.arpSelectModeTarget);

        //positions.editModeTarget.copy(VSSObjects.controlKeys.children[0].position);
        selectArp(PanelState.state);
        selectSample(PanelState.state);

        VSSObjects.group.add(group);
    });


    function getObject(name) {
        return VSSObjects.controlKeys.children.find(o=>o.name === name);
    }   

    
  function setPosition (object, target, rate = 0.5) {
    object.position.x += (target.x-object.position.x)*rate;
    object.position.z += (target.z-object.position.z)*rate;
    }   

  function updateEnvMap(cubeTexture) {}

  function update(time) {
        editModeMesh.position.y = 0.6 + (1+Math.sin(time*4))*0.05;
        setPosition(soundSelection, positions.selectedSampleTarget);
        setPosition(arpSelection, positions.selectedArpTarget);
  }

  return {
    updateEnvMap,
    update
  };
};