import { Vector2 } from "three";

export const MOUSE_MOVE_EVENT = "mousemove";
export const TOUCH = {
    CANCEL : "touchcancel",
    END : "touchend",
    MOVE : "touchmove",
    START : "touchstart",
}


export var MOUSE_POSITION = new Vector2();

export var TOUCH_POSITIONS = [];

window.addEventListener(MOUSE_MOVE_EVENT, onMouseMove);

window.addEventListener(TOUCH.MOVE, onTouchMove);
window.addEventListener(TOUCH.END, onTouchMove)

function convertCoordToScreen(event, vec) {
    return vec.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
      );
}

function onMouseMove(event) {
    convertCoordToScreen(event, MOUSE_POSITION);
  }

function onTouchMove(event) {
    TOUCH_POSITIONS = [];
    for(let i = 0; i<event.targetTouches.length; i++) {
        TOUCH_POSITIONS.push(convertCoordToScreen(event.targetTouches.item(i), new Vector2()))
    }
    MOUSE_POSITION = TOUCH_POSITIONS[0] || new Vector2();
}