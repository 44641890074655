import ReactGA from 'react-ga';

ReactGA.initialize('UA-79009216-2');
ReactGA.pageview(window.location.pathname + window.location.search);

export const track = (action, category = "app") => {

    ReactGA.event({
        category: category,
        action: action
    });

}