import { Object3D } from "three";

const rigs = [];

export default (scene, camera) => {

  let defaultRig = createRig();

      defaultRig.add(camera);

      camera.position.z = 6;

    scene.add(defaultRig);

  function createRig () {
    let group = new Object3D();
    rigs.push(group);
    group.name = `CameraRig_${rigs.length}`
    return group;
  }


  window.addEventListener("mousemove", onMouseMove);

  function onMouseMove (e) {
      //defaultRig.rotation.x = e.clientX/window.innerWidth;
  }

  function updateEnvMap (cubeTexture) {

  }


  function update(time) {
   // group.rotateX(0.01)
  }


  return {
    updateEnvMap,
    update
  };
};