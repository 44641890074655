export const MOUSE ={
    WHEEL_UP: "Mouse.WHEEL_UP",
    WHEEL_DOWN: "Mouse.WHEEL_DOWN",
    RIGHT_BUTTON: "Mouse.RIGHT_BUTTON",
    LEFT_BUTTON: "Mouse.LEFT_BUTTON",
}

export const SLIDERS = {
    REVERB_SLIDER: "Fader_ReverbSize",
    FILTER_SLIDER: "Fader_FilterCutoff",
}

const keymap = {
    
    Button_AllCancel: [27],
    Button_Arp_Stop: [0],
    Button_Demo_StartStop: [0],
    Button_MP_PlayStop: [32],
    Button_MP_Record: [0],
    Button_Tempo_Down: [0],
    Button_Tempo_Up: [0],
    
    Button_DataEntry_Down: [189, 109, MOUSE.WHEEL_DOWN],
    Button_DataEntry_Up: [187, 107, MOUSE.WHEEL_UP],
    
    Button_Attack: [49],
    Button_Decay: [50],
    Button_Sustain: [51],
    Button_Release: [52],
    
    Button_Loop: [53],
    Button_PingPong: [54],
    Button_Reverse: [55],
    Button_Echo: [56],
    Button_Fuzz: [57],
    Button_Filter: [48],
    Button_Reverb: [220],
    
    Button_Vibrato: [0],
    
    Button_Sample: [192],
    Button_Overwrite: [0],
    
    Button_ModeSelect_ArpSelect: [9],
    
    Button_Select_1: [81],
    Button_Select_2: [87],
    Button_Select_3: [69],
    Button_Select_4: [82],
    Button_Select_5: [84],
    Button_Select_6: [89],
    Button_Select_7: [85],
    Button_Select_8: [73],
    Button_Select_9: [79],
    Button_Select_10: [80],
    Button_Select_11: [219],
    Button_Select_12: [221],

    Fader_ReverbSize: [SLIDERS.REVERB_SLIDER],
    Fader_FilterCutoff: [SLIDERS.FILTER_SLIDER],

}
    
let functionMap = [];
Object.keys(keymap).forEach(func=>{
    let keys = keymap[func];
    keys.forEach(key=>{
        functionMap[key] = func;
    })
})

export default functionMap;