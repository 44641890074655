import Memo from "../../Utils/Memo";

const MAX_HISTORY = 10;

class PanelState extends Memo {

    constructor () {
        super();
        this._history = [];
    }

    get length () {
        return this._history.length-1;
    }
    set state (value) {
        if(this._history.length>MAX_HISTORY) {
            this._history.shift();
        }
        this._history.push(value);
        this.trigger("update", this.state);
    } 

    get state () {
        return this._history[this.length];
    }

    undo() {
        this._history.pop();
        this.trigger("update", this.state);
    }


}

const PanelStateInstance = new PanelState();

export default PanelStateInstance;