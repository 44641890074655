import {Color} from "three";

export const COLORS = {
    pure_white: new Color(0xFFFFFF),
    white: new Color(0xAAAAAA),
    red:  new Color(0xC35944),
    yellow: new Color(0xE2B957),
    darkblue: new Color(0x4B6BBE).addScalar(-0.2),
    blue: new Color(0x4B6BBE),
    lightblue: new Color(0xaaffff),
    green: new Color(0x36BCB2),
    grey: new Color(0x272727),
    darkGrey: new Color(0x101010),
    lightgrey: new Color(0x505050),
    pink: new Color(0xC3446E),
    teal: new Color(0x44C3C3),
    black: new Color(0x151530),
}

export const MOUSE_WHEEL_EVENT = (/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel";