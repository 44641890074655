const AUDIO_BPS = 2000000;
const VIDEO_BPS = 2000000;
const DEFAULT_OPTIONS = {
  file: 'MediaRecording_',
  mimeType: "video/webm",
  codec: "vp8",
  extension: 'webm',
  audioBitsPerSecond: AUDIO_BPS, // 1 Mbps
  bitsPerSecond: VIDEO_BPS // 2 Mbps
};

export default class TapeDeck {
  static get AUDIO_FORMAT() {
    if(window.MediaRecorder !== undefined) {
      return MediaRecorder.isTypeSupported('audio/webm;codecs=opus') ? 'audio/webm;codecs=opus' : 'audio/ogg;codecs=opus';
    }
    return false;
  }
  static get RECORD_MODE() {
    return {
      VIDEO_ONLY: "TapeDeck.VIDEO_ONLY",
      AUDIO_ONLY: "TapeDeck.AUDIO_ONLY",
      FULL_VIDEO: "TapeDeck.FULL_VIDEO"
    };
  }

  static get DEFAULT_OPTIONS () {
      return Object.assign({}, DEFAULT_OPTIONS);
  }

  static get isSupported () {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia && window.MediaStreamAudioDestinationNode);
  }

  constructor(context,  options = TapeDeck.DEFAULT_OPTIONS ,recordMode = TapeDeck.RECORD_MODE.VIDEO_ONLY,) {

   // console.log(TapeDeck.isSupported)

    this.source = new MediaSource({});
    this.input = TapeDeck.isSupported ? context.createMediaStreamDestination() : context.createGain();
    this.options = options;
    this.recorder = null;
    this.recordMode = recordMode
    this.videoStream = null;
    this.audioStream = this.input.stream;
    this.audioFormat = TapeDeck.AUDIO_FORMAT;

    if(this.recordMode === TapeDeck.RECORD_MODE.AUDIO_ONLY && this.audioFormat) {
      this.options.mimeType = this.audioFormat.split(";codecs=")[0];
      this.options.codec    = this.audioFormat.split(";codecs=")[1]
    }
    this.blobs = [];
  }

  assignVideoStream (canvas) {
    this.videoStream = canvas.captureStream();
  }

  get recording() {
    if(this.recorder) {
    return this.recorder.state === "recording";
    } 
    return false;
  }

  handleSourceOpen() {
    this.sourceBuffer = this.source.addSourceBuffer(`${this.options.mimeType}; codecs="${this.options.codec}"`);
  }

  addListeners() {
    this.source.addEventListener(
      "sourceopen",
      this.handleSourceOpen.bind(this),
      false
    );
  }

  removeListeners() {}

  handleDataAvailable(event) {
    if (event.data && event.data.size > 0 && this.recording) {
      this.blobs.push(event.data);
    }
  }

  handleStop(event) {
    if (event.data && event.data.size > 0 && this.recording) {
      this.blobs.push(event.data);
    }
  }

  record() {
    this.blobs = [];
    let stream = null;

    switch (this.recordMode) {
      case TapeDeck.RECORD_MODE.VIDEO_ONLY:
        stream = new MediaStream(this.videoStream);
        break;
      case TapeDeck.RECORD_MODE.AUDIO_ONLY:
        stream = new MediaStream(this.audioStream);
        break;
        default:
      case TapeDeck.RECORD_MODE.FULL_VIDEO:
        stream = new MediaStream([
          ...this.audioStream.getTracks(),
          ...this.videoStream.getTracks(),
        ]);
        break;
    }

    if (stream != null) {
      this.recorder = new MediaRecorder(stream, this.options);
      this.recorder.onstop = this.handleStop.bind(this);
      this.recorder.ondataavailable = this.handleDataAvailable.bind(this);
      this.recorder.start(100); // collect 100ms of data
    }
  }

  download() {
    const blob = new Blob(this.blobs, {
      type: `${this.options.mimeType},codecs=${this.options.codec}`
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${this.options.file}_${Date.now()}.${this.options.extension}`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  stop() {
    this.recorder.stop();
  }

} 
