import {Vector2, BoxGeometry, PlaneGeometry, MeshBasicMaterial, MeshStandardMaterial, Mesh, DoubleSide, AdditiveBlending, BufferGeometry, BufferAttribute } from "three"

export const generateUVMap = function (xRatio, yRatio, widthRatio, heightRatio) {
    return [[
                [
                    new Vector2(xRatio,yRatio),  
                    new Vector2(xRatio,yRatio+heightRatio),
                    new Vector2(xRatio+widthRatio,yRatio),
                ],
                [
                    new Vector2(xRatio,yRatio+heightRatio), 
                    new Vector2(xRatio+widthRatio,yRatio+heightRatio), 
                    new Vector2(xRatio+widthRatio,yRatio), 
                ],
            ]];
}

export const makeUIObject = function (w, h, scale, texture, uv) {

    let geometry = new PlaneGeometry( w*scale, h*scale, 1, 1 );
    let material = new MeshBasicMaterial({transparent: true, side:DoubleSide })
        material.map = texture;
        material.emissiveMap = texture;
        //material.alphaMap = texture;
        material.blending =  AdditiveBlending;
        material.alphaTest = 0.1;
        material.needsUpdate = true;

        geometry.faceVertexUvs = uv;
        geometry.uvsNeedUpdate = true;

    return new Mesh( geometry, material );

}

export const triangle = function (w, h, scale, color = 0xFFFFFF) {

    var geometry = new BufferGeometry();
    var vertices = new Float32Array( [
        (-w/2) * scale, h * scale,  0,
        (w/2) * scale, h * scale,  0,
        0,  0,  0,
    ]);
    geometry.addAttribute( 'position', new BufferAttribute( vertices, 3 ) );

    let material = new MeshBasicMaterial({color: color, transparent: true, side:DoubleSide })
        material.blending =  AdditiveBlending;
        //material.alphaTest = 0.1;
        material.needsUpdate = true;

    return new Mesh( geometry, material );

}

export const indicator = function (w, h, scale, color = 0xFFFFFF) {

    var geometry = new PlaneGeometry(w*scale, h*scale);
    let material = new MeshBasicMaterial({color: color, transparent: true, side:DoubleSide })
        material.blending =  AdditiveBlending;
        //material.alphaTest = 0.1;
        material.needsUpdate = true;

    return new Mesh( geometry, material );

}

export const cube = function (w,h,d, color) {

    var geometry = new BoxGeometry(w, h, d);
    let material = new MeshStandardMaterial({color: color, transparent: true, roughness:1 });
        material.needsUpdate = true;

    return new Mesh( geometry, material );

}