import PanelState from "../VSS/Panel/PanelState";
import { Object3D, Group, MeshStandardMaterial, MeshPhongMaterial, PointLight, DoubleSide } from "three";
import GLTFLoader from "three-gltf-loader";

import { track } from "../Utils/Analytics";

import {MOUSE_POSITION} from "../Utils/Mouse";

import {COLORS} from "../Constants"

const MODEL = require("../Assets/Logo.glb");

export default (scene, camera) => {
  let group = new Group();
      group.name = "IntroScene";

  let active = true;
  let enable = true;
  let initialPos = camera.position.clone();

  let boxes = new Group();
  let logo = new Object3D();

  window.addEventListener("click", ()=>{
    active = false;
    navigator.mediaDevices.getUserMedia({ audio: true })
    //PanelState.trigger("showKeyboard");
    track("started")
  })

  

  var LogoMat = new MeshPhongMaterial({
    color: COLORS.green,
    //flatShading: true,
    //emissive: COLORS.green,
    //emissiveIntensity: 0.2,
    shininess: 0,
    transparent: true,
  });

  var text = new MeshPhongMaterial({
    color: COLORS.pure_white,
    transparent: true,
    shininess: 0,
  })

  var backplane = new MeshPhongMaterial({
    color: COLORS.black,
    //blending: AdditiveBlending,
    shininess: 0,
    transparent: true,
  })

  var backplane2 = new MeshPhongMaterial({
    color: COLORS.red,
    //blending: AdditiveBlending,
    shininess: 0,
    transparent: true,
  })

  var materials = [LogoMat, text, backplane];

  let light2 = new PointLight(COLORS.white, 2, 12);
  light2.position.set(0, 0, 10); 
  light2.intensity = 4;

  const loader = new GLTFLoader();
  let colors = [COLORS.yellow, COLORS.red, COLORS.green, COLORS.blue];
  let blocks = new Object3D();
  loader.load(
    MODEL,
    function(gltf) {
      gltf.scene.traverse(function(child) {

        if(child.name === "Logo")
        logo = child;
        logo.scale.set(1, 5, 1);
        if (child.material) {
          child.material = LogoMat;
        }

        if(child.name === "Experience") {
          child.material = text;
        }

        if(child.name === "Backface") {
          child.material = backplane;
        }

        if(child.name === "Backface2") {
          child.material = backplane2;
        }
       
        if(child.name === "Lines") {
          blocks = child;
        }

        if(/Block/.test(child.name)) {
          let mat = new MeshStandardMaterial({
            color: colors[Math.floor(Math.random()*colors.length)], 
            transparent: true, 
            roughness:1,
            side: DoubleSide })
          child.material = mat;
        }

      });

      
      // for(let i = 0; i< 100; i++) {
      //   let c = cube(0.2, .2, 5+Math.random()*5, colors[Math.floor(Math.random()*colors.length)]);
      //   c.position.set(0.5-Math.random(), (0.5-Math.random())*10, 0.5-Math.random())
      //   boxes.add(c)
        
      // }
      // boxes.rotateZ(1);
      // boxes.rotateY(2);
      // boxes.position.set(0, -5, -5)
      // boxes.scale.set(5, 5, 5);
      // boxes.translateZ(-16);
      group.add(light2, gltf.scene, boxes);
      group.scale.set(0.3, 0.3, 0.3)
      group.position.set(0, 0, 2)
      scene.add(group);
    },
    function(xhr) {},
    function(error) {}
  );

  function updateEnvMap (cubeTexture) {
    scene.background = cubeTexture;
    materials.forEach((m)=>{
      m.envMap = cubeTexture;
      m.needsUpdate = true;
    })
  }


  function update(time, dt) {
    if(enable) {
    if(active) {
      logo.rotation.set(1.5+(MOUSE_POSITION.y*0.02), 0, MOUSE_POSITION.x*0.1);
      group.rotation.set(MOUSE_POSITION.y*0.05, MOUSE_POSITION.x*0.01, 0);
      camera.position.z = initialPos.z + (MOUSE_POSITION.y * 0.2);
      blocks.rotateY(0.001);
      blocks.traverse((c)=>{
       // console.log(c)
       if(c.name !== "Lines") {
        c.rotateZ(0.001);
       }
      })
    } else {
      light2.intensity -= 0.1;
      blocks.translateY(-0.1);
      materials.forEach(m=>{
        m.opacity -= 0.05;
        m.needsUpdate = true;  
        
        if(light2.intensity <= 0) {
          enable =false;
          scene.remove(group);
          PanelState.trigger("showKeyboard");
        }

      })
    }
  }
  }

  return {
    updateEnvMap,
    update
  };
};
