import React, { Component } from "react";

import ThreeFrame from "./ThreeFrame";
import Menu from "./Menu";

export default class Main extends Component {

    state = {
        menu_open: false,
        selected: null
    }

    componentWillMount () {

    }

    selectMenuItem = (menuItem) => {
       // const alreadyOpen = this.state.menu_open;

        if(menuItem === this.state.selected) {
            this.closeMenu();
            return;
        }

        // if(alreadyOpen && menuItem !== this.state.selected) {
        //     this.setState({menu_open:false});
        //     setTimeout(()=>{
        //         this.setState({menu_open:true, selected: menuItem});
        //     }, 200);

        //     return;
        // }

        this.setState({menu_open:true, selected: menuItem});

    }

    closeMenu = () => {
        this.setState({menu_open:false});
        setTimeout(()=>{
            this.setState({menu_open:false, selected: null});
        }, 600);   
    }
    

    render () {
        return (
            <div>
                    <ThreeFrame open={!this.state.menu_open}/>
                    <Menu
                        open={this.state.menu_open} 
                        selected={this.state.selected} 
                        selectItem={this.selectMenuItem} 
                        closeMenu={this.closeMenu}
                    />
            </div>
        );
    }

}