import PanelState from "./Panel/PanelState";
import {Group, Raycaster, AdditiveBlending, MeshBasicMaterial, Color } from "three";
import GLTFLoader from "three-gltf-loader";

import {MOUSE_POSITION} from "../Utils/Mouse";

import {COLORS} from "../Constants";

import PK from "./PianoKeyboard";
import PanelController from "./Panel/PanelController";
import UITexture from "../Views/VssUICanvas";

const MODEL = require("../Assets/VSS_Final3b.glb");
const KEY_DOWN_ROTATION = 0.09;

const BASE_ROTATION = 1.4;

const CONTROLS_GROUPS = {
  KEYBED: "Keybed",
  DISPLAYS: "Display",
  FADERS: "FaderControls",
  KEYBOARD: "Keyboard",
  CONTROLS: "Controls"
}

const FADER_POSITION_LIMITS = {
  MAX: 4.3462377834320005,
  MIN: 3.466237783432007,
}

export const UI = new UITexture(512);

export default (scene, camera, envMap) => {
  let group = new Group();
      group.name = "VSSScene"

  let interactables = [];

  const RegKeyMaterial = RegExp('White');
  
  let init = false;

  var defaultColor = COLORS.pink;

  var keyColor = (name) => {
    return RegKeyMaterial.test(name) ? new Color(0xaaffff) : new Color(0x113333);
  }

  const raycaster = new Raycaster();

  var piano = [];
  var controls = [];
  var faders = [];

  var pianoKeys = null;
  var controlKeys = null;
  var faderControls = null;
  var displayScreens = [];

  var materials = [];

  var colors = {};

  var currentFaderControl = null;

  const faderValues = {
    Fader_ReverbSize: "REVERB_SIZE",
    Fader_FilterCutoff: "FILTER_CUTOFF",
  }

  PanelState.on("update",(state)=>{
    faderControls.children.forEach(c=>{
      setFaderPosition(c, state[faderValues[c.name]]);
    })
  })

  PanelState.on("showKeyboard", ()=>{

    const loader = new GLTFLoader();
  loader.load(
    MODEL,
    function(gltf) {
      gltf.scene.traverse(function(child) {
        if (child.material) {
         
          if(child.material.name === "Display") {

            let mat = new MeshBasicMaterial({transparent: true })

            mat.map = UI.texture;
            mat.emissiveMap = UI.texture;
            //mat.alphaMap = UI.texture;
            mat.blending =  AdditiveBlending;
            mat.alphaTest = 0.1;
            displayScreens.push(mat);
            child.material = mat;
          } else {
            child.material.envMap = envMap;
            child.material.envMapIntensity = 0.2;
          }

          
          child.material = child.material.clone();
          child.material.needsUpdate = true;

          colors[child.material.uuid] = child.material.color;

          materials.push(child.material);
        }
      });

      displayScreens = gltf.scene.children.find(c=>c.name === CONTROLS_GROUPS.DISPLAYS);
      faderControls = gltf.scene.children.find(c=>c.name===CONTROLS_GROUPS.FADERS);
      pianoKeys = gltf.scene.children.find(c=>c.name===CONTROLS_GROUPS.KEYBED);
      controlKeys = gltf.scene.children.find(c=>c.name===CONTROLS_GROUPS.CONTROLS);

      group.add(gltf.scene);
      
      group.rotateX(1.4);
      scene.add(group);

      PanelState.trigger('loadedKeyboard', {
        group,
        faderControls,
        pianoKeys,
        controlKeys,
        displayScreens,
      })

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mousedown", onMouseDown);
      window.addEventListener("mouseup", onMouseUp);
    },
    function(xhr) {},
    function(error) {}
  );

  })

  function updateEnvMap (cubeTexture) {
    materials.forEach((m)=>{
      
      m.envMap = cubeTexture;
      m.needsUpdate = true;

    })
  }

  function setFaderPosition(object, ratio) {
      object.position.x = FADER_POSITION_LIMITS.MIN + (FADER_POSITION_LIMITS.MAX - FADER_POSITION_LIMITS.MIN) * ratio;
  }

  function faderRatio (control) {
    return (control.position.x-FADER_POSITION_LIMITS.MIN)/(FADER_POSITION_LIMITS.MAX-FADER_POSITION_LIMITS.MIN);
  }


  function onMouseMove(event) {
    
    if(currentFaderControl) {
      currentFaderControl.position.x += event.movementX*0.01;
      const ratio = faderRatio(currentFaderControl)
      if(ratio > 1) {
        currentFaderControl.position.x = FADER_POSITION_LIMITS.MAX;
      } else if (ratio < 0 ) {
        currentFaderControl.position.x = FADER_POSITION_LIMITS.MIN;
      }
      PanelController.setValue(currentFaderControl.name, faderRatio(currentFaderControl) )
    }


    init=true;
  }

  function onMouseDown(event) {

    faders.forEach(m => {
      currentFaderControl = m.object;
    });

    controls.forEach(m => {
      PanelController.buttonDown(m.object.name);
      //PanelState.trigger("clicked", m.object)
    });
  }

  function onMouseUp(event) {

    if(currentFaderControl) {
      currentFaderControl = null;
    }

    controls.forEach(m => {
      PanelController.buttonUp(m.object.name);
    });
  }

  function update(time) {
    if (pianoKeys && controlKeys) {
      drawUsedKeys();

      if(init) {
        group.rotation.x = BASE_ROTATION + MOUSE_POSITION.y*0.1;
        group.rotation.z = MOUSE_POSITION.x*0.1;
       raycaster.setFromCamera(MOUSE_POSITION, camera);
       rayCastPianoKeys();
       rayCastControlPanel();
       rayCastFaderPanel();
       setCursor();
      }
    }
  }

  function setCursor() {
    if(controls.length > 0 || piano.length > 0) {
      document.body.style.cursor = "pointer";
    } else {
      document.body.style.cursor = "default";
    }
  }

  function drawUsedKeys() {
    pianoKeys.children.forEach(m => {
      m.rotation.set(0, 0, 0);
      m.material.color = colors[m.material.uuid];
    });

    PK.keys.forEach(k => {
      let name = PK.reverseConvert(k);
      let m = pianoKeys.children.find(k => k.name === name);
      if (m) {
        m.rotation.set(KEY_DOWN_ROTATION, 0, 0);
        m.material.color = keyColor(m.material.name);
      }
    });
  }

  function rayCastPianoKeys() {
    let oldkeys = piano.slice();

    piano = raycaster.intersectObject(pianoKeys, true);
    piano.forEach(m => {
      PK.add(PK.convertValue(m.object.name));
    });

    let expiredKeys = oldkeys.filter(m => {
      return piano.findIndex(on => on.object.name === m.object.name) === -1;
    });

    expiredKeys.forEach(m => {
      PK.remove(PK.convertValue(m.object.name));
    });
  }

  function rayCastFaderPanel () {
    let oldButtons = faders.slice();

    faders = raycaster.intersectObject(
      faderControls,
      true
    );

    faders.forEach(m => {
      m.object.material.color = defaultColor;
    });

    let expiredButtons = oldButtons.filter(m => {
      return faders.findIndex(on => on.object.name === m.object.name) === -1;
    });

    expiredButtons.forEach(m => {
      m.object.material.color = colors[m.object.material.uuid];
    });

  }

  function rayCastControlPanel () {
    let oldButtons = controls.slice();

    controls = raycaster.intersectObject(
      controlKeys,
      true
    );

    controls.forEach(m => {
      m.object.material.color = defaultColor;
    });

    let expiredButtons = oldButtons.filter(m => {
      return controls.findIndex(on => on.object.name === m.object.name) === -1;
    });

    expiredButtons.forEach(m => {
      m.object.material.color = colors[m.object.material.uuid];
    });

  }

  return {
    updateEnvMap,
    update,
    interactables
  };
};
