import React, { Component } from "react";

import ThreeContainer from "./ThreeContainer";

export default class ThreeFrame extends Component {


        render() {
            return (<div id="ThreeFrame" className={this.props.open ? `open` : `closed`}>
                <ThreeContainer />
            </div>);
        }

}