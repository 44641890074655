import PanelState from "../VSS/Panel/PanelState";
import { PointLight, RectAreaLight, Group, AmbientLight} from 'three';

const TITLE_SCREEN = {
	target:0,
	target2: 1,
}

const KEYBOARD_SCREEN = {
	target: 1,
	target2: 0
}


export default (scene, camera) => {    

	let active = false;

	let target = 0;
	let target2 = TITLE_SCREEN.target2;

	let group = new Group();
		group.name = "GeneralLights"

	let light = new RectAreaLight( 0xffffff, 40,  70, 0); 
		light.position.set(0, 5, 4);
		light.rotation.x = 3;
		light.rotation.y = 0.02;

	var light2 = new AmbientLight( 0x2020A0, 0); 

	//  let light2 = new PointLight(0xFFFFFF, 2, 12);
	//  light2.position.set(10, 5, 0); 
	//  light2.rotation.y = 2;
	//  let light3 = new PointLight(0xFFFFFF, 2, 12);
	//  light3.position.set(0, 5, 0);

	// var helper = new CameraHelper( light.shadow.camera );
	// scene.add( helper );

	light2.intensity = target2;

	group.add(light, light2);
	scene.add(group)
	PanelState.on("showKeyboard", ()=>{
		target2 = KEYBOARD_SCREEN.target2;
	})

	PanelState.on("loadedKeyboard", ()=>{
		active = true;
		target = 1;
		setTimeout(()=>{
			
			target2 = 0;
	   }, 1000)
	})

    function update(time) {
		if(active) {
			light.height += (target-light.height)*0.05;
			light2.intensity += (target2-light2.intensity)*0.5;
		}
		
    }
    return {
		update
    }
}


				// // OPACITY
				// var intensityKeyframes = new NumberKeyframeTrack( '.intensity', [ 0, 1, 2 ], [ 1, 0, 1 ] );
				// var heightKeyframes = new NumberKeyframeTrack( '.height', [ 0, 1, 2 ], [ 1, 0, 1 ] );

				// // create an animation sequence with the tracks
				// // If a negative time value is passed, the duration will be calculated from the times of the passed tracks array
				// var intensityClip = new AnimationClip( 'Action', 3, [ intensityKeyframes ] );
				// var heightClip = new AnimationClip('Action', 3, [heightKeyframes]);

				// // create a ClipAction and set it to play
				// var intensityClipAction = mixer.clipAction( intensityClip, light2 );
				// var heightClipAction = mixer.clipAction( heightClip, light1 );
				// 	intensityClipAction.play();
				// 	heightClipAction.play();