import * as Scream from "Scream";

const DEFAULT_SAMPLE = require(`../Assets/samples/01_Piano.mp3`);
const {VSS30} = Scream.Synths;
const {Sample} = Scream.Components;

export default (m, Audio) => {

	var vss30 = new VSS30(Audio);
	vss30.addEffect(Scream.Effects.Delay);
	vss30.addEffect(Scream.Effects.Saturate);
	vss30.addEffect(Scream.Effects.Filter);
	vss30.addEffect(Scream.Effects.Reverb);
	vss30.connectEffects();
	vss30.connect(Audio.destination);

	const p = new Sample(Audio);
	p.load(DEFAULT_SAMPLE).then(
		(res) => {
			vss30.setSample(res);
		});


	vss30.effects[3].wet.gain.setTargetAtTime(3, Audio.currentTime, 0.01)	

	// random buffer		
	// setInterval(()=>{
	// 	vss30.loopEnd = Math.random();
	// }, 2000)


	// self record
	// var s = new Sample(Audio, "STREAM");
	// vss30.output.connect(s.recordInput);
	// s.record();
	// setTimeout(()=>{
	// 	s.stopRecording();
	// 	vss30.output.disconnect(s.recordInput);
	// 	vss30.setSample(s);

	// }, 3000)

	return {
		vss30
	}

}