import VSSSynth from "./VSSSynth";
import Mizzy from "Mizzy";
import TapeDeck from "../Utils/MediaRecorder";
import Scheduler from "../Utils/Scheduler";

export const Audio = new (window.AudioContext || window.webkitAudioContext)();

const MIDI_CHANNEL = 1

export const countIn = new Scheduler();
        countIn.loop = false;

export const musicRecorder = new Scheduler();

function processTick (e, d) {
    d.forEach(n=> {
        const note = Mizzy.Generate.MidiEvent(n.data.data, "C");
        m.sendMidiMessage(note, MIDI_CHANNEL);
    });
}

musicRecorder.on("tick", processTick.bind(this));

// musicRecorder.on("endRecord",()=>{
//     console.log(musicRecorder.serialize())
// })

const scalemap = [2,1,2,2,1,2,2];
export const demoTrack = new Scheduler();
var rootNote = 51;
for(let i = 0; i<21; i++) {
    
    const note_on = Mizzy.Generate.NoteEvent(Mizzy.NOTE_ON, rootNote, 100);
    const note_off = Mizzy.Generate.NoteEvent(Mizzy.NOTE_OFF, rootNote, 100);
    demoTrack.addToSchedule(note_on, i*80);
    demoTrack.addToSchedule(note_off, i*80+60);
    demoTrack.length += 80;
    const inc = scalemap[i%scalemap.length];
    rootNote+=inc;
}

demoTrack.on("tick", processTick.bind(this));



export const m = new Mizzy();
export const vss30 = new VSSSynth(m, Audio).vss30;

export const recorder = new TapeDeck(Audio,{
    file: 'MediaRecording_',
    mimeType: "audio/webm",
    codec: "opus",
    extension: 'webm',
  }, TapeDeck.RECORD_MODE.AUDIO_ONLY);

    vss30.connect(recorder.input);

  m.initialize().then(() => {
    m.bindToAllInputs();
    m.bindKeyboard();
    m.keyToggleRange(0, 127, (e) => {
        vss30.NoteOn(e);
        if(musicRecorder.recording) {
            musicRecorder.addToSchedule(e);
        }
    }, (e) => {
        vss30.NoteOff(e);
        if(musicRecorder.recording) {
            musicRecorder.addToSchedule(e);
        }
    }, MIDI_CHANNEL);

    // m.onCC(0, (e) => {
    // 	vss30.output.gain.value = e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(1, (e) => {
    // 	vss30.loopLength = e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(2, (e) => {
    // 	vss30.loopStart = e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(3, (e) => {
    // }, MIDI_CHANNEL);
    // m.onCC(4, (e) => {
    // 	vss30.effects[0].effect.frequency.value = 40 + (e.ratio * 7600);
    // }, MIDI_CHANNEL);
    // m.onCC(5, (e) => {
    // 	vss30.effects[0].effect.Q.value = 100 * e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(6, (e) => {
    // }, MIDI_CHANNEL);
    // m.onCC(7, (e) => {
    // }, MIDI_CHANNEL);
    // m.onCC(8, (e) => {
    // 	vss30.effects[1].effect.delayTime.value = e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(9, (e) => {
    // 	vss30.effects[1].filter.effect.frequency.value = 160 + e.ratio * 1500;
    // }, MIDI_CHANNEL);
    // m.onCC(10, (e) => {
    // 	vss30.effects[1].filter.effect.Q.value = e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(11, (e) => {
    // 	vss30.effects[1].feedback.gain.value = e.ratio * 1.1;
    // }, MIDI_CHANNEL);
    // var decayTime = setTimeout(() => {
    // }, 0);
    // m.onCC(12, (e) => {
    // 	clearTimeout(decayTime);
    // 	decayTime = setTimeout(() => {
    // 		vss30.effects[2].decayTime = e.ratio * 8;
    // 	}, 100);
    // }, MIDI_CHANNEL);
    // m.onCC(13, (e) => {
    // 	vss30.effects[2].wet.gain.value = e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(14, (e) => {
    // 	vss30.effects[2].dry.gain.value = e.ratio;
    // }, MIDI_CHANNEL);
    // m.onCC(15, (e) => {
    // }, MIDI_CHANNEL);
    // m.onCC(64, (e) => {
    // 	switch (e.value) {
    // 		case 0:
    // 			vss30.stopRecording();
    // 			break;
    // 		case 127:
    // 			vss30.record();
    // 			break;
    // 	}
    // }, MIDI_CHANNEL);
});

// function MidiDataToHex (value) {
//     let newData = [];
//     newData[0] = parseInt(value[0], 16);
//     newData[1] = parseInt(value[1], 16);
//     newData[2] = parseInt(value[2], 16);
//     return newData;
// }