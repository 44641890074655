import { CanvasTexture } from "three";
import { Audio, vss30 } from "../VSS/AudioSuite";
import PanelActions, {EDIT_MODES, RECORD_STATES, COUNT_IN} from "../VSS/Panel/PanelActions";
import { COLORS } from "../Constants";
import Scheduler from "../Utils/Scheduler";

const ON_COLOR = colorString(COLORS.teal, 1); //"rgba(0, 255, 255, 1)";
const OFF_COLOR = colorString(COLORS.lightgrey, 1); 

const LINE_STYLE = {
    STROKE_STYLE: ON_COLOR,
    LINE_CAP: "round",
    LINE_WIDTH: 12,
}

if (global.AnalyserNode && !global.AnalyserNode.prototype.getFloatTimeDomainData) {
    var uint8 = new Uint8Array(2048);
    global.AnalyserNode.prototype.getFloatTimeDomainData = function(array) {
      this.getByteTimeDomainData(uint8);
      for (var i = 0, imax = array.length; i < imax; i++) {
        array[i] = (uint8[i] - 128) * 0.0078125;
      }
    };
  }
  

const analyser = Audio.createAnalyser();
const gain = Audio.createGain();
      gain.gain.value = 0;
      analyser.connect(gain)
      gain.connect(Audio.destination);
      analyser.fftSize = Math.pow(2, 10);
var dataArray = new Float32Array(analyser.fftSize); // Float32Array needs to be the same length as the fftSize 


vss30.connect(analyser);

function POSITIONS(size) {

    const full = Math.round(size*1);
    const half = Math.round(size*0.5);
    const quarter =Math.round( size*0.25);

    return {
        ADSR: {
            x: 0,
            y: 0,
            width: full,
            height: quarter
        },
        LOOP: {
            x: 0,
            y: quarter,
            width: half,
            height: quarter
        },
        PING_PONG: {
            x: half,
            y: quarter,
            width: half,
            height: quarter
        },
        REVERSE: {
            x: 0,
            y: half,
            width: quarter,
            height: quarter
        },
        FUZZ: {
            x: quarter,
            y: half,
            width: quarter,
            height: quarter
        },
        FILTER: {
            x: half,
            y: half,
            width: quarter,
            height: quarter
        },
        ECHO: {
            x: quarter+half,
            y: half,
            width: quarter,
            height: quarter
        },
        REVERB: {
            x: 0,
            y: quarter+half,
            width: quarter,
            height: quarter
        },
        INDICATOR_1: {
            x: quarter,
            y: quarter+half,
            width:quarter/2,
            height:quarter/2,
        },
        INDICATOR_2: {
            x: quarter+quarter/2,
            y: quarter+half,
            width:quarter/2,
            height:quarter/2,
        },
        INDICATOR_3: {
            x: quarter,
            y: quarter+half+quarter/2,
            width:quarter/2,
            height:quarter/2,
        },
        INDICATOR_4: {
            x: quarter+quarter/2,
            y: quarter+half+quarter/2,
            width:quarter/2,
            height:quarter/2,
        },
        RECORD_PROGRESS: {
            x: half,
            y: quarter+half,
            width: quarter,
            height: quarter,
        },
        MUSIC_PLAYBACK_PROGRESS: {
            x: quarter+half,
            y: quarter+half,
            width: quarter,
            height: quarter,
        },
        DEMO_PROGRESS: {
            x: quarter,
            y: quarter+half,
            width: quarter,
            height: quarter,
        }
    }
}

function sin (t) {
   return 1+Math.sin(t);
} 

function colorString(color, alpha) {
    return `rgba(${color.r*255},${color.g*255},${color.b*255},${alpha})`;
}


function normalRamp (waveform) {
    return waveform.map((v,i)=>{
        return v * (1-(i/waveform.length));
    });
}

function loopRamp (waveform) {
    return waveform.map((v,i)=>{
        return v * (1-(((i*2)%waveform.length)/waveform.length));
    });
}

function pingPongRamp (waveform) {
    return waveform.map((v,i)=>{
        return v * (1-((i*2)/waveform.length));
    });
}

function reverseRamp (waveform) {
    return waveform.map((v,i)=>{
        return v * (i/waveform.length);
    });
}

function fuzzRamp (waveform) {
    return waveform.map((v)=>{
        if(Math.abs(v) < 0.25) return 0;
        return v > 0 ? 0.5 : -0.5;
    });
}

 const filter = [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.52, 0.55, 0.6, 0.7, 0.8, 0.90, 1];


function applyMargin(position, margin) {
    const {x, y, width, height} = position;
    var obj = {x: x + margin, y: y + margin, width: width - (margin*2), height: height - (margin*2)}
    return obj;
  }


function normalize(buffer) {
    const b = buffer.slice();

    const va = -0.8; // a
    const vb = 0.8;  // b

    let vmin = -0.05; // A
    let vmax = 0.05; // B

    b.forEach(v=>{
        if(v>vmax) {vmax = v};
        if(v<vmin) {vmin = v};
    });

    return b.map(v=>{
        return va + (v - vmin) * (vb - va) / (vmax - vmin);
    })
}

  const MARGIN = 15;

  let start = Date.now();
  let time = Date.now();

export default class UITexture {

    constructor (size = 1024) {
        const canvas = document.createElement("canvas");
        this.canvas = canvas;

        this.size = size;

        this.margin = MARGIN*(this.size/1024);

        this.ctx = this.canvas.getContext("2d");
		this.ctx.canvas.width = size;
		this.ctx.canvas.height = size;
        

        this.texture = new CanvasTexture(this.canvas);
        this.texture.flipY = false;
        this.ADSRData = [1,1,0.5,0.2];
        this.ADSRSize = applyMargin(POSITIONS(this.size).ADSR, this.margin);
        this.ADSRSizeNoMargin = POSITIONS(this.size).ADSR;
        this.ADSRPos = [0,0,0,0];
        this.ADSRPosNoMargin = [0,0,0,0];

        this.loopDimensions     = applyMargin(POSITIONS(this.size).LOOP, this.margin);
        this.loopDimensionsNoMargin     = POSITIONS(this.size).LOOP;
        this.pingpongDimensions = applyMargin(POSITIONS(this.size).PING_PONG, this.margin);
        this.reverseDimensions  = applyMargin(POSITIONS(this.size).REVERSE, this.margin);
        this.echoDimensions     = applyMargin(POSITIONS(this.size).ECHO, this.margin);
        this.fuzzDimensions     = applyMargin(POSITIONS(this.size).FUZZ, this.margin);
        this.filterDimensions   = applyMargin(POSITIONS(this.size).FILTER, this.margin);
        this.reverbDimensions   = applyMargin(POSITIONS(this.size).REVERB, this.margin);
        this.recordProgress = applyMargin(POSITIONS(this.size).RECORD_PROGRESS, this.margin);
        this.musicRecorderProgress = applyMargin(POSITIONS(this.size).MUSIC_PLAYBACK_PROGRESS, this.margin);
        this.demoProgress = applyMargin(POSITIONS(this.size).DEMO_PROGRESS, this.margin);

        this.update();

    }

    drawWaveform(position, waveform = [0,1,0,1], color = LINE_STYLE.STROKE_STYLE) {
        const ctx = this.ctx;
		const {x, y, width, height} = position
        ctx.beginPath();
        ctx.moveTo(x, y + waveform[0]*height);
        ctx.fillStyle = `rgba(0,0,0,0)`;
        ctx.strokeStyle = color
        ctx.lineCap= LINE_STYLE.LINE_CAP;
        ctx.lineWidth = LINE_STYLE.LINE_WIDTH*(this.size/1024);

        const sec = (width / waveform.length);
            
        for(let i = 0; i < waveform.length; i++) {
            ctx.lineTo(x + i * sec, y + (waveform[i])*height);
        }

        ctx.moveTo(width, y + height*0.5);
        ctx.fill();
        ctx.stroke();
        ctx.fillStyle = `rgba(0,0,0,0)`;
    }

    drawBuffer(position, waveform = [0,1,0,1], skip = 16, color = LINE_STYLE.STROKE_STYLE) {
       const {x, y, width, height} = position;
       const sec = (width / waveform.length);
       const center = y + (height*0.5);
       this.ctx.beginPath();
       this.ctx.fillStyle = `rgba(0,0,0,0)`;
       this.ctx.moveTo(x, center);
       this.ctx.strokeStyle = color;
       this.ctx.lineCap= LINE_STYLE.LINE_CAP;
       this.ctx.lineWidth = LINE_STYLE.LINE_WIDTH*(this.size/1024);

       for(var i = 0; i < waveform.length; i+=skip) {
        this.ctx.lineTo(x + (i * sec), center + (waveform[i]*0.5)*height);
       }

       this.ctx.moveTo(width-sec, center);
       this.ctx.fill();
       this.ctx.stroke();
   }

    drawEditMode(mode, dt) {
        this.ctx.fillStyle = colorString(COLORS.pink, sin(time*0.02));
        var {x, y, width, height} = this.ADSRSizeNoMargin;
        var quarterWidth = width*0.25;
        switch(mode) {
            default: break;
            case EDIT_MODES.ADSR_A:
                this.ctx.fillRect(x, y+height-15, quarterWidth-10, 15);
            break;
            case EDIT_MODES.ADSR_D:
                this.ctx.fillRect(x+quarterWidth, y+height-15, quarterWidth, 15);
            break;
            case EDIT_MODES.ADSR_S:
                this.ctx.fillRect(x+quarterWidth*2, y+height-15, quarterWidth, 15);
            break;
            case EDIT_MODES.ADSR_R:
                this.ctx.fillRect(x+quarterWidth*3, y+height-15, quarterWidth, 15);
            break;
            case EDIT_MODES.LOOP_EDIT:
                var {x1, y1, width1, height1} = this.loopDimensionsNoMargin;
                this.ctx.fillRect(x1, y1+height1-15, width1, 15);
            break;

        }
    }


    drawIndicator(color = COLORS.pink, dimensions = POSITIONS(this.size).INDICATOR_1) {
        var {x, y, width, height} = dimensions;
        this.ctx.beginPath();
        this.ctx.fillStyle = colorString(color, 1);
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + width, y);
        this.ctx.lineTo(x + width*.5, y+height);
        this.ctx.lineTo(x, y);
        this.ctx.fill();
    }

    calcADSRPos(pos) {

        const { x, y, width, height } = pos;
        let ADSRpos = new Array(4);

        const q = width / 4; // break the width into quarters
        ADSRpos[0] = x + this.ADSRData[0] * q; // draw a line from the start to the width of a quarter scaled to the amount of attack
        ADSRpos[1] = ADSRpos[0] + this.ADSRData[1] * q; // draw a line from the attack width to the width of a quarter scaled to the amount of decay
        ADSRpos[2] = y + (height * (1 - this.ADSRData[2]));
        ADSRpos[3] = x + width - (this.ADSRData[3] * q); // draw a line from  the start, plus the width, - the with of a quarter scaled to the release

        return ADSRpos;

    }

    updateADSRPos() {

        this.ADSRData[0] += (PanelActions.state.ADSR[0] - this.ADSRData[0]) * 0.6;
        this.ADSRData[1] += (PanelActions.state.ADSR[1] - this.ADSRData[1]) * 0.6;
        this.ADSRData[2] += (PanelActions.state.ADSR[2] - this.ADSRData[2]) * 0.6;
        this.ADSRData[3] += (PanelActions.state.ADSR[3] - this.ADSRData[3]) * 0.6;

        this.ADSRPos = this.calcADSRPos(this.ADSRSize);
        this.ADSRPosNoMargin = this.calcADSRPos(this.ADSRSizeNoMargin);

    }

    drawADSREnvelope () {
        const {x, y, width, height} = this.ADSRSize;
        const envHeight = y + height; // total height "the bottom"
        this.ctx.beginPath();
        
        this.ctx.strokeStyle = LINE_STYLE.STROKE_STYLE
        this.ctx.lineCap= LINE_STYLE.LINE_CAP;
        this.ctx.lineWidth = LINE_STYLE.LINE_WIDTH*(this.size/1024);

        this.ctx.moveTo(x, y + height);
        this.ctx.lineTo(this.ADSRPos[0], y); 
        this.ctx.lineTo(this.ADSRPos[1], this.ADSRPos[2]);
        this.ctx.lineTo(this.ADSRPos[3], this.ADSRPos[2]);
        this.ctx.lineTo(x + width, envHeight);
        this.ctx.moveTo(x + width, y + height)

        this.ctx.stroke();
    }

    drawLOOP (waveform) {
        this.drawBuffer(this.loopDimensions, loopRamp(waveform), 16, PanelActions.state.LOOP ? ON_COLOR : OFF_COLOR)
    }

    drawPingPong (waveform) {
        this.drawBuffer(this.pingpongDimensions, pingPongRamp(waveform), 16, PanelActions.state.PINGPONG ? ON_COLOR : OFF_COLOR)
    }

    drawReverse(waveform) {
        this.drawBuffer(this.reverseDimensions, reverseRamp(waveform), 64, PanelActions.state.REVERSE ? ON_COLOR : OFF_COLOR)
    }

    drawEcho(waveform) {
        this.drawBuffer(this.echoDimensions, normalRamp(loopRamp(waveform)), 32, PanelActions.state.ECHO ? ON_COLOR : OFF_COLOR);
    }

    drawFuzz (waveform) {
        this.drawBuffer(this.fuzzDimensions, fuzzRamp(waveform), 32, PanelActions.state.FUZZ ? ON_COLOR : OFF_COLOR);
    }

    drawFilter () {
        this.drawWaveform(this.filterDimensions, filter, PanelActions.state.FILTER ? ON_COLOR : OFF_COLOR);
    }

    drawReverb () {
        let {x, y, width} = this.reverbDimensions;
        let ctx = this.ctx;

        let oX = 25;
        let oY = 30;

        let offsetX = 4;
        let offsetY = 18;

        let sX = offsetX+x;
        let sY = offsetY+y;

        let bW = width/1.5;
        let bH = width/2;
        ctx.strokeStyle = PanelActions.state.REVERB ? ON_COLOR : OFF_COLOR;
        ctx.strokeRect(sX+oX, sY+oY, bW, bH);
        ctx.beginPath();
        ctx.setLineDash([5, 15]);
        ctx.strokeRect(sX, sY, bW, bH);
        ctx.moveTo(sX+oX, sY+oY);
        ctx.lineTo(sX, sY);
        ctx.moveTo(sX+bW, sY+bH);
        ctx.lineTo(sX+bW+oX, sY+bH+oY);
        ctx.moveTo(sX, sY+bH);
        ctx.lineTo(sX+oX, sY+bH+oY);
        ctx.moveTo(sX+bW, sY);
        ctx.lineTo(sX+bW+oX, sY+oY);
        ctx.stroke();
        ctx.setLineDash([]);
    }


    update () {
        let currentTime = Date.now() - start;
        let dt = currentTime - time;
        time = currentTime;
       
        analyser.getFloatTimeDomainData(dataArray);
        
        this.updateADSRPos(dt);
        this.draw(dt);
    }

    drawRadialProgress(x = 0, y = 0, radius = 64, t = 0.5, width = 10, colorA = COLORS.red, colorB = COLORS.pink) {
        this.ctx.lineWidth = width;
        this.ctx.beginPath();
        this.ctx.strokeStyle = colorString(colorA, 0.8);
        this.ctx.arc(x, y, radius, 0, (2 * Math.PI) * t, false);
        this.ctx.stroke();
        this.ctx.strokeStyle = colorString(colorB, 1);
        this.ctx.beginPath();
        this.ctx.arc(x, y, radius, (2 * Math.PI) * t - 0.1, (2 * Math.PI) * t, false);
        this.ctx.stroke();
    }

    drawCircle(x = 0, y = 0, radius = 64, width = 10, colorA = COLORS.red, alpha = 1) {
        this.ctx.beginPath();
        this.ctx.fillStyle = colorString(colorA, alpha);
        this.ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
        this.ctx.fill();
    }

    drawRecordProgress(t, colorA = COLORS.red, colorB = COLORS.pink, pos = this.recordProgress) {
        let {x, y, width, height} = pos;
            this.drawRadialProgress(x+width/2, y+height/2, width/2, t, 6, colorA, colorB);
    }

    drawRecordCircle(t, colorA = COLORS.red, alpha = 1, pos = this.recordProgress) {
        let {x, y, width, height} = pos;
            this.drawCircle(x+width/2, y+height/2, width/3, width/4, colorA, alpha);
    }

    draw (dt) {
        setTimeout(this.update.bind(this),1000/12);

        this.ctx.fillStyle = `rgba(0,0,0,0.6)`;
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

        this.drawEditMode(PanelActions.state.EDIT_MODE, dt);
        this.drawADSREnvelope(PanelActions.state.ADSR, this.ADSRData);

        let data = normalize(dataArray);

        this.drawLOOP(data)
        this.drawPingPong(data)
        this.drawReverse(data);
        this.drawEcho(data);
        this.drawFuzz(data);
        this.drawFilter(data);
        this.drawReverb(data);

        // this.drawIndicator(COLORS.white, POSITIONS(this.size).INDICATOR_1);
        // this.drawIndicator(COLORS.blue, POSITIONS(this.size).INDICATOR_2);
        // this.drawIndicator(COLORS.red, POSITIONS(this.size).INDICATOR_3);
        // if(PanelActions.state.EDIT_MODE !== EDIT_MODES.DEFAULT) {
        //     this.drawIndicator(COLORS.green, POSITIONS(this.size).INDICATOR_4);
        // }

        switch(PanelActions.state.DEMO_STATE) {
            default: break;
            case Scheduler.STATES.PLAYING:
                this.drawRecordProgress(PanelActions.state.MUSIC_RECORDER_POS, COLORS.yellow, COLORS.white, this.demoProgress);
            break;
        }

        switch(PanelActions.state.MUSIC_RECORDER_STATE) {
            default: break;
            case Scheduler.STATES.WAITING_FOR_INPUT: 
                this.drawRecordCircle(PanelActions.state.MUSIC_RECORDER_POS, COLORS.blue, Math.sin(Date.now()*0.05), this.musicRecorderProgress);
            break;
            case Scheduler.STATES.RECORDING: 
                this.drawRecordCircle(PanelActions.state.MUSIC_RECORDER_POS, COLORS.red, Math.sin(Date.now()*0.05), this.musicRecorderProgress);
            break;
            case Scheduler.STATES.PLAYING:
                this.drawRecordProgress(PanelActions.state.MUSIC_RECORDER_POS, COLORS.blue, COLORS.white, this.musicRecorderProgress);
            break;
        }
        

        switch (PanelActions.state.RECORD_STATE) {
            default:
            break;
            case RECORD_STATES.RECORDING:
                this.drawRecordCircle(vss30.samplingProgress, COLORS.red, Math.sin(Date.now()*0.05));
                this.drawRecordProgress(vss30.samplingProgress);
            break;
            case RECORD_STATES.OVERWRITING:
                this.drawRecordCircle(vss30.samplingProgress, COLORS.red, Math.sin(Date.now()*0.05));
                this.drawRecordProgress(vss30.samplingProgress);
            break;
            case RECORD_STATES.COUNTING_IN:
                this.drawRecordProgress((PanelActions.state.RECORD_START-Date.now()) / COUNT_IN, COLORS.blue, COLORS.pink);
            break;
        }

        this.texture.needsUpdate = true;

    }

}