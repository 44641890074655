import Mizzy from "Mizzy";
import {m} from "./AudioSuite";

const MIDI_CHANNEL = 1;

const regex = /([0-9])+/;

// instantiate an instance


var root_note = 52;



export default class PianoKeyboard {
  static get keys() {
    return Object.keys(m.keysPressed).map(n=>parseInt(n, 10));
  }

  static get root_note() {
    return root_note;
  }

  static set root_note(value) {
    if (value !== root_note) {
      root_note = value;
    }
  }

  static convertValue(value) {
    const num = value.match(regex);
    return PianoKeyboard.root_note + parseInt(num[0], 10);
  }

  static reverseConvert(note_number) {
    return `Key_${note_number - PianoKeyboard.root_note}`;
  }

  static isKeyDown(note_number) {
    return PianoKeyboard.keys.indexOf(note_number) > -1;
  }

  static add(note_number) {
    if (!PianoKeyboard.isKeyDown(note_number)) {
      const note = Mizzy.Generate.NoteEvent(Mizzy.NOTE_ON, note_number, 100);
      m.sendMidiMessage(note,MIDI_CHANNEL);
    }
  }

  static remove(note_number) {
    if (PianoKeyboard.isKeyDown(note_number)) {
      const note = Mizzy.Generate.NoteEvent(Mizzy.NOTE_OFF, note_number, 100);
      m.sendMidiMessage(note,MIDI_CHANNEL);
    }
  }
}
