import PanelState from "./PanelState";
import * as Scream from "Scream";

import { track } from "../../Utils/Analytics";

import {Audio, vss30, musicRecorder, countIn, demoTrack, m} from "../AudioSuite";
import Scheduler from "../../Utils/Scheduler";
const {Sample} = Scream.Components;

export const RECORD_STATES = {
    PLAYING: "PLAYING",
    WAITING_FOR_INPUT: "WAITING_FOR_INPUT",
    COUNTING_IN: "COUNTING_IN",
    RECORDING: "RECORDING",
    OVERWRITING: "OVERWRITING",
    STOPPED: "STOPPED"
}

export const SAMPLE_MODES = {
    RECORD: "RECORD",
    OVERWRITE: "OVERWRITE",
}

const SAMPLE_SELECTIONS = new Array(12);
SAMPLE_SELECTIONS[0] = "./assets/samples/01_Piano.mp3";
SAMPLE_SELECTIONS[1] = "./assets/samples/02_Strings.mp3";
SAMPLE_SELECTIONS[2] = "./assets/samples/03_Pizzicato.mp3";
SAMPLE_SELECTIONS[3] = "./assets/samples/04_BassGuitar.mp3";
SAMPLE_SELECTIONS[4] = "./assets/samples/05_Glow.mp3";
SAMPLE_SELECTIONS[5] = "Sample";
SAMPLE_SELECTIONS[6] = "./assets/samples/06_AirdockMoment.mp3";
SAMPLE_SELECTIONS[7] = "./assets/samples/07_Synthwave.mp3";
SAMPLE_SELECTIONS[8] = "./assets/samples/08_ChurchOrgan.mp3";
SAMPLE_SELECTIONS[9] = "./assets/samples/09_FMko.mp3";
SAMPLE_SELECTIONS[10] = "./assets/samples/10_FMBell.mp3";
SAMPLE_SELECTIONS[11] = "./assets/samples/11_808.mp3";

const ARP_SELECTIONS = new Array(12).map(e=>0);

const CONFIG = {
    ClearSelectionMode: true
}

const ADSR_DELTA_AMOUNT = 0.1;
const LOOP_DELTA_AMOUNT = 0.01;

const DEFAULT_BPM = 60;
const BPM_DELTA_AMOUNT = 10;
const BPM_MAX = 240;
const BPM_MIN = 40;

export const RECORD_LENGTH = 2000;
export const COUNT_IN = 1500;

export const EDIT_MODES = {
    DEFAULT: "EDIT_MODE.DEFAULT",
    ADSR_A:"EDIT_MODE.ADSR_A",
    ADSR_D:"EDIT_MODE.ADSR_D",
    ADSR_S:"EDIT_MODE.ADSR_S",
    ADSR_R:"EDIT_MODE.ADSR_R",
    ARP_SELECT: "EDIT_MODE.ARP_SELECT",
    LOOP_EDIT: "EDIT_MODE.LOOP_EDIT",
}

const DEFAULT_STATE = {
    VIBRATO: false,
    EDIT_MODE: EDIT_MODES.DEFAULT,
    SELECTION_MODE: EDIT_MODES.DEFAULT,
    ADSR:[0.0001,1,0.5,1],
    LOOP: false,
    LOOP_END: 1,
    PINGPONG: false,
    REVERSE: false,
    ECHO: false,
    FUZZ: false,
    FILTER: false,
    FILTER_CUTOFF: 1,
    REVERB: false,
    REVERB_SIZE: 0,
    SAMPLE_MODE: SAMPLE_MODES.RECORD,
    SAMPLE_SELECTION: SAMPLE_SELECTIONS[0],
    SAMPLE_SELECTION_VALUE: 0,
    ARP_ENABLED: false,
    ARP_SELECTION: ARP_SELECTIONS[0],
    ARP_SELECTION_VALUE: 0,
    BPM: DEFAULT_BPM*2,
    BPM_MS: 500,
    RECORD_STATE: RECORD_STATES.STOPPED,
    RECORD_START: 0,
    MUSIC_RECORDER_STATE: Scheduler.STATES.STOPPED,
    MUSIC_RECORDER_POS: 0,
    DEMO_STATE: Scheduler.STATES.STOPPED,
}

function checkMax (value, min, max) {
    if(value > max) { return max; }
    if(value < min) { return min; }
    return value;
}

let state = Object.assign({}, DEFAULT_STATE, {updated: Date.now()})

musicRecorder.on("record", ()=>{
    state.MUSIC_RECORDER_STATE = musicRecorder.state;
})

musicRecorder.on("tick", (t)=>{
    state.MUSIC_RECORDER_POS = t;
})

demoTrack.on("tick", (t)=>{
    state.MUSIC_RECORDER_POS = t;
})

let ACTIONS = {
    Button_AllCancel: ()=>{
        state = Object.assign(DEFAULT_STATE, {updated: Date.now()});
    },

    Button_Arp_Stop: ()=>{

    },

    Button_Demo_StartStop: ()=>{
        musicRecorder.stop();
        if(demoTrack.playing) {
            demoTrack.stop();
            m.panic()
        } else {
            demoTrack.play();
        }
        state.MUSIC_RECORDER_STATE = musicRecorder.state;
        state.DEMO_STATE = demoTrack.state;
    },

    Button_MP_PlayStop: ()=>{
        demoTrack.stop();
        if(musicRecorder.playing) {
            musicRecorder.stop();
            m.panic()
        } else {
            if(musicRecorder.recording) musicRecorder.stop();
            musicRecorder.play();
        }
        state.DEMO_STATE = demoTrack.state;
        state.MUSIC_RECORDER_STATE = musicRecorder.state;
    },
    Button_MP_Record: ()=>{
        if(musicRecorder.recording) {
            musicRecorder.stop();
        } else {
            musicRecorder.stop();
            musicRecorder.reset();
            musicRecorder.record(true);
        }
        state.MUSIC_RECORDER_STATE = musicRecorder.state;
    },

    Button_Tempo_Down: ()=>{
        PanelActions.doCommand("BPM_SET", {delta: -BPM_DELTA_AMOUNT});
    },
    Button_Tempo_Up: ()=>{
        PanelActions.doCommand("BPM_SET", {delta: BPM_DELTA_AMOUNT});
    },

    BPM_SET: ({delta})=>{
        state.BPM = checkMax(state.BPM+delta, BPM_MIN, BPM_MAX);
        state.BPM_MS = convertBPMtoMS(state.BPM);
    },

    ADSR_A: ({delta})=>{
        state.ADSR[0] = checkMax(state.ADSR[0]+delta, 0.0001,1);
    },
    ADSR_D: ({delta})=>{
        state.ADSR[1] = checkMax(state.ADSR[1]+delta, 0,1);
    },
    ADSR_S: ({delta})=>{
        state.ADSR[2] = checkMax(state.ADSR[2]+delta, 0,1);
    },
    ADSR_R: ({delta})=>{
        state.ADSR[3] = checkMax(state.ADSR[3]+delta, 0,1);
    },

    LOOP_SET: ({delta})=>{
        state.LOOP_END = checkMax(state.LOOP_END+delta,0.01,1);
    },

    Button_DataEntry_Up: ()=>{
        switch(state.EDIT_MODE) {
            default:
            case EDIT_MODES.DEFAULT:
            break;
            case EDIT_MODES.LOOP_EDIT:
                PanelActions.doCommand("LOOP_SET", {delta: -LOOP_DELTA_AMOUNT});
            break;
            case EDIT_MODES.ADSR_A:
                PanelActions.doCommand("ADSR_A", {delta: -ADSR_DELTA_AMOUNT})
            break;
            case EDIT_MODES.ADSR_D:
                PanelActions.doCommand("ADSR_D", {delta: -ADSR_DELTA_AMOUNT})
            break;
            case EDIT_MODES.ADSR_S:
                PanelActions.doCommand("ADSR_S", {delta: -ADSR_DELTA_AMOUNT})
            break;
            case EDIT_MODES.ADSR_R:
                PanelActions.doCommand("ADSR_R", {delta: -ADSR_DELTA_AMOUNT})
            break;
        }
    },
    Button_DataEntry_Down: ()=>{
        switch(state.EDIT_MODE) {
            default:
            case EDIT_MODES.DEFAULT:

            break;
            case EDIT_MODES.LOOP_EDIT:
                PanelActions.doCommand("LOOP_SET", {delta: LOOP_DELTA_AMOUNT});
            break;
            case EDIT_MODES.ADSR_A:
                PanelActions.doCommand("ADSR_A", {delta: ADSR_DELTA_AMOUNT})
            break;
            case EDIT_MODES.ADSR_D:
                PanelActions.doCommand("ADSR_D", {delta: ADSR_DELTA_AMOUNT})
            break;
            case EDIT_MODES.ADSR_S:
                PanelActions.doCommand("ADSR_S", {delta: ADSR_DELTA_AMOUNT})
            break;
            case EDIT_MODES.ADSR_R:
                PanelActions.doCommand("ADSR_R", {delta: ADSR_DELTA_AMOUNT})
            break;
            case EDIT_MODES.ARP_SELECT:

            break;
        }
    },
    
    Button_Attack: ()=>{
        state.EDIT_MODE = EDIT_MODES.ADSR_A
    },
    Button_Decay: ()=>{
        state.EDIT_MODE = EDIT_MODES.ADSR_D
    },
    Button_Sustain: ()=>{
        state.EDIT_MODE = EDIT_MODES.ADSR_S
    },
    Button_Release: ()=>{
        state.EDIT_MODE = EDIT_MODES.ADSR_R
    },
    
    Button_Loop: ()=>{
        state.LOOP = !state.LOOP;
        if(state.LOOP) {
            state.EDIT_MODE = EDIT_MODES.LOOP_EDIT;
        } else {
            state.EDIT_MODE = EDIT_MODES.DEFAULT;
        }
    },
    Button_PingPong: ()=>{
        state.PINGPONG = !state.PINGPONG;    
    },
    Button_Reverse: ()=>{
        state.REVERSE = !state.REVERSE;   
        vss30.toggleReverse();
    },
    Button_Echo: ()=>{
        state.ECHO = !state.ECHO;   
    },
    Button_Fuzz: ()=>{
        state.FUZZ = !state.FUZZ;   
    },
    Button_Filter: ()=>{
        state.FILTER = !state.FILTER;   
    },
    Button_Reverb: ()=>{
        state.REVERB = !state.REVERB;   
    },
    
    Button_Vibrato: ()=>{
        state.VIBRATO = !state.VIBRATO;
    },
    
    Button_Sample: ()=>{
        if (state.RECORD_STATE === RECORD_STATES.STOPPED) {
            state.SAMPLE_MODE = SAMPLE_MODES.RECORD;
            state.RECORD_START = Date.now();
            state.RECORD_STATE = RECORD_STATES.COUNTING_IN;
            setTimeout(() => {
                state.RECORD_STATE = RECORD_STATES.RECORDING;
                state.RECORD_START = Date.now();
                vss30.record(RECORD_LENGTH, false).then(() => {
                    state.RECORD_STATE = RECORD_STATES.STOPPED;
                });
            }, COUNT_IN);
        }
    },
    Button_Overwrite: ()=>{
        if (state.RECORD_STATE === RECORD_STATES.STOPPED) {
            state.SAMPLE_MODE = SAMPLE_MODES.OVERWRITE;
            state.RECORD_START = Date.now();
            state.RECORD_STATE = RECORD_STATES.COUNTING_IN;
            setTimeout(() => {
                state.RECORD_STATE = RECORD_STATES.OVERWRITING;
                state.RECORD_START = Date.now();
                vss30.record(RECORD_LENGTH, true).then(() => {
                    state.RECORD_STATE = RECORD_STATES.STOPPED;
                });
            }, COUNT_IN);
        }
    },
    
    Button_ModeSelect_ArpSelect: ()=>{
        if(state.SELECTION_MODE === EDIT_MODES.DEFAULT) {
            state.SELECTION_MODE = EDIT_MODES.ARP_SELECT;
        } else {
            state.SELECTION_MODE = EDIT_MODES.DEFAULT;
        }
    },
    
    Select_Sample: ({SAMPLE_SELECTION})=>{
        state.SAMPLE_SELECTION = SAMPLE_SELECTIONS[SAMPLE_SELECTION];
        state.SAMPLE_SELECTION_VALUE = SAMPLE_SELECTION;
        const p = new Sample(Audio);
        p.load(state.SAMPLE_SELECTION).then(
            (res) => {
                vss30.setSample(res);
            });
    },

    Select_Arp: ({ARP_SELECTION})=>{
        state.ARP_SELECTION = ARP_SELECTIONS[ARP_SELECTION];
        state.ARP_SELECTION_VALUE = ARP_SELECTION;
    },

    Select({value}) {
        switch(state.SELECTION_MODE) {
            default:
            case EDIT_MODES.DEFAULT:
                PanelActions.doCommand("Select_Sample", {SAMPLE_SELECTION:value});
            break;
            case EDIT_MODES.ARP_SELECT:
                PanelActions.doCommand("Select_Arp", {ARP_SELECTION:value});
                if(CONFIG.ClearSelectionMode) {
                    state.SELECTION_MODE = EDIT_MODES.DEFAULT;
                }
            break;
        }
    },

    Button_Select_1: ()=>{
        PanelActions.doCommand("Select", {value:0});
    },
    Button_Select_2: ()=>{
        PanelActions.doCommand("Select", {value:1});
    },
    Button_Select_3: ()=>{
        PanelActions.doCommand("Select", {value:2});
    },
    Button_Select_4: ()=>{
        PanelActions.doCommand("Select", {value:3});
    },
    Button_Select_5: ()=>{
        PanelActions.doCommand("Select", {value:4});
    },
    Button_Select_6: ()=>{
        PanelActions.doCommand("Select", {value:5});
    },
    Button_Select_7: ()=>{
        PanelActions.doCommand("Select", {value:6});
    },
    Button_Select_8: ()=>{
        PanelActions.doCommand("Select", {value:7});
    },
    Button_Select_9: ()=>{
        PanelActions.doCommand("Select", {value:8});
    },
    Button_Select_10: ()=>{
        PanelActions.doCommand("Select", {value:9});
    },
    Button_Select_11: ()=>{
        PanelActions.doCommand("Select", {value:10});
    },
    Button_Select_12: ()=>{
        PanelActions.doCommand("Select", {value:11});
    },
    Fader_ReverbSize: (value)=>{
        state.REVERB_SIZE = value;
    },
    Fader_FilterCutoff: (value)=>{
        state.FILTER_CUTOFF = value;
    },
}

m.onCC(21, (e) => {
    PanelActions.doCommand("Fader_FilterCutoff", e.ratio)
}, 1);

function convertBPMtoMS (BPM) {
    const beatsPerSecond = BPM/60;
    return 1000/beatsPerSecond;
}



export default class PanelActions {

    static doCommand(command, options = {}) {
        ACTIONS[command](options);
        track(command, "panel");
        PanelActions.applyState()
    }

    static applyState () {
        const {state} = PanelActions

        if(state.BPM !== PanelState.state.BPM) {
        musicRecorder.speed = countIn.speed = demoTrack.speed = state.BPM / DEFAULT_BPM;
        }
        vss30.attack = state.ADSR[0];
        vss30.decay = state.ADSR[1];
        vss30.sustain = state.ADSR[2];
        vss30.release = state.ADSR[3];
        if(vss30.loop !== state.loop) {
            vss30.loop = state.LOOP;
        }
        if(state.PINGPONG !== PanelState.PINGPONG) {
            vss30.loopMode = state.PINGPONG ? "PINGPONG" : "NORMAL";
        }
        vss30.loopEnd = state.LOOP_END;

        vss30.effects[0].bypass(!state.ECHO);
        vss30.effects[0].feedback.gain.value = state.REVERB_SIZE * 1.1;

        vss30.effects[1].bypass(!state.FUZZ);
        if(state.FUZZ) {
            vss30.effects[1].output.gain.setTargetAtTime(0.15, Audio.currentTime, 0.01);
        } else {
            vss30.effects[1].output.gain.setTargetAtTime(1, Audio.currentTime, 0.01);
        }

        vss30.effects[2].bypass(!state.FILTER);
        vss30.effects[2].effect.frequency.value = 100 + (state.FILTER_CUTOFF * 7600);

        vss30.effects[3].bypass(!state.REVERB);

        PanelState.state = state;
    }

    static undo () {
        PanelState.undo();
        PanelActions.state = PanelState.state;
        PanelActions.applyState();
    }

    static get state () {
        return state;
    }

}
PanelState.state = state;
PanelActions.applyState();