import { Group } from "three";
import GLTFLoader from "three-gltf-loader";

const MODEL = require("../Assets/Set.glb");


export default (scene, camera) => {
  let group = new Group();
      group.name = "SetPiece"

  var materials = [];

  const loader = new GLTFLoader();
  loader.load(
    MODEL,
    function(gltf) {
      gltf.scene.traverse(function(child) {
        if (child.material) {
            
           // console.log(child)
           //child.material.emissive = new Color(0x121212);
            //child.material.wireframe = true;
            //child.material.needsUpdate = true;
        }
      });
      //group.rotateX(9*Math.PI/180)
      group.add(gltf.scene);
      group.receiveShadow = true;
      scene.add(group);
    },
    function(xhr) {},
    function(error) {}
  );

  function updateEnvMap (cubeTexture) {

    scene.background = cubeTexture;
    materials.forEach((m)=>{
      m.envMap = cubeTexture;
      m.needsUpdate = true;
    })
  }


  function update(time) {
   // group.rotateX(0.01)
  }


  return {
    updateEnvMap,
    update
  };
};
