import React, {Component} from 'react';
import ThreeEntryPoint from '../Three/ThreeEntryPoint';

export default class ThreeContainer extends Component {

    componentDidMount() {
        ThreeEntryPoint(this.ThreeEntryPoint);
    }

    render () {
        return (
            <div ref={element => this.ThreeEntryPoint = element} />
        )
    }

}