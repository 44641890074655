import PanelActions from "./PanelActions";
import functionMap, {MOUSE} from "./PanelFunctionMap";

import { MOUSE_WHEEL_EVENT } from "../../Constants";

const keysPressed = [];

export default class PanelController {

    static get keys () {
        return keysPressed;
    }

    static get state () {
        return PanelActions.state;
    }

    static addListeners() {
        window.addEventListener("keydown",  PanelController.keydown);
        window.addEventListener("keyup",  PanelController.keyup);
        window.addEventListener(MOUSE_WHEEL_EVENT, PanelController.wheel)
    }
    static removeListeners () {
        window.removeEventListener("keydown", PanelController.keydown);
        window.removeEventListener("keyup", PanelController.keyup);
    }

    static setValue(target, value) {
        let command = functionMap[target];
        PanelActions.doCommand(command, value);
    }

    static keydown(e) {
        if(functionMap[e.keyCode]) {
            e.preventDefault();
            let command = functionMap[e.keyCode];
            PanelController.buttonDown(command);
        }
    }
    static keyup(e) {
        if(functionMap[e.keyCode]) {
            e.preventDefault();
            let command = functionMap[e.keyCode];
            PanelController.buttonUp(command);
        }
    }

    static wheel(e) {
        const wheeldata = e.deltaY ? e.deltaY : e.detail;
        e.preventDefault();
        const dir = wheeldata > 0 ? MOUSE.WHEEL_UP : MOUSE.WHEEL_DOWN;
        if(functionMap[dir]) {
            let command = functionMap[dir];
            PanelController.buttonDown(command);
            setTimeout(()=>{
                PanelController.buttonUp(command);
            },20)
        }
    }

    static buttonDown (command) {
        if(keysPressed.indexOf(command) === -1) {
            keysPressed.push(command);
            PanelActions.doCommand(command);
        }
    }

    static buttonUp (command) {
        let index = keysPressed.indexOf(command) ;
        if(index > -1) {
            keysPressed.splice(index,1);
        }
    }  
}
PanelController.addListeners();