import VSSScene from "./VSS/VSSScene";
import UIStateScene from "./VSS/UIStateScene";
import SetPiece from "./Scenes/SetPiece";
import GeneralLights from "./Scenes/GeneralLights";
import CameraController from "./Scenes/CameraController";
import IntroScene from "./Scenes/IntroScene";

const scenes =  [
    VSSScene,
    GeneralLights,
    SetPiece,
    CameraController,
    UIStateScene,
    IntroScene,
]

export default scenes;