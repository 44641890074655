import React, { Component } from "react";

export class MenuItem extends Component {

    static defaultProps = {
        title: "",
        index: 0,
        class: ""
    }

    render () {

        return (
            <div className={`button ${this.props.class}`} onClick={()=>this.props.selectItem(this.props.index)}>
                {this.props.title}
            </div>
        )

    }

}

export default class Menu extends Component {


        static defaultProps = {
            items: [],
            selected: null,
            selectItem: () => {}
        }

        handleSelection (e, selection) {
            this.props.selectItem(selection);
        }

        render() {

            let buttons = document.getElementsByClassName("button");
            // for (var element of buttons) {
            //     element.classList.remove("selected");
            // }
            if(this.props.selected !== null) {
                let selected = document.getElementsByClassName(this.props.selected)[0];
                selected.classList.add("selected");
            }

            return (
                <div id="Menu" className={this.props.open ? `open` : `closed`}>
                    <div className="upper">
                        
                        {/* 
                            <img className="logo" src={logo} alt="VSS-30"/>
                            <div className="buttons">
                            <div className="button red" onClick={(e)=>this.handleSelection(e,"red")}>
                                Using the <br/>
                                VSS-30
                                <img src={close_menu} alt="Close"/>
                            </div>
                            <div className="button green" onClick={(e)=>this.handleSelection(e,"green")}>
                                Keyboard<br/>
                                Layout
                                <img src={close_menu} alt="Close"/>
                            </div>
                            <div className="button yellow" onClick={(e)=>this.handleSelection(e,"yellow")}>
                                Arpeggio <br/>
                                Designer 
                                <img src={close_menu} alt="Close"/>
                            </div>
                            <div className="button blue" onClick={(e)=>this.handleSelection(e,"blue")}>
                                MIDI <br/>
                                Control 
                                <img src={close_menu} alt="Close"/>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className={`lower ${this.props.selected}`}>
                        <div className="slider">
                            <div className="panel red">&nbsp;</div>
                            <div className="panel green">&nbsp;</div>
                            <div className="panel yellow">&nbsp;</div>
                            <div className="panel blue">&nbsp;</div>
                        </div>
                    </div> */}
                </div>
            )
        }
}